import React from "react";

const Hero = () => {
  return (
    <section id="home" className="section">
      <div>
        <h1>Stéfan Martin</h1>
        <h5>A student on a never-ending journery of learning</h5>
        <a className="btn" href="#about">Learn More</a>
      </div>
    </section>
  )
}

export default Hero;